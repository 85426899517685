import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "./Card";
import { eductionScript } from "./Education.Script";
import ExpandInfo from "components/Education/ExpandInfo/ExpandInfo";
import ExistingQualifications from "../ExistingQualifications";
import MiscAttributes from "components/MiscAttributes";

const Education = () => {
  let { user } = useSelector((state) => state.user);
  const [courses, setCourses] = useState([]);
  const [displayCourses, setDisplayCourses] = useState([]);
  const [isDisplayTitle, setIsDisplayTitle] = useState(false);

  useEffect(() => {
    const length_ = Math.ceil(
      user?.courseData?.length > 0 ? user?.courseData?.length / 3 : 0
    );
    const data = Array.from({ length: length_ }, (_i, j) => j * 3);
    if (user?.courseData) {
      const data = user.courseData.filter((cardData) => {
        if (
          !cardData?.displayUnits &&
          ((cardData.overallGradeData !== "Ongoing" &&
            cardData?.module?.modules === 0 &&
            cardData?.unit?.units === 0) ||
            (cardData.overallGradeData === "Ongoing" &&
              cardData?.module?.modules > 0) ||
            cardData?.unit?.units > 0 ||
            (cardData.overallGradeData !== "Ongoing" &&
              cardData?.module?.modules > 0) ||
            cardData?.unit?.units > 0 ||
            checkAttachments(cardData))
        ) {
          return cardData;
        }
      });
      setDisplayCourses(data);
    }
    setCourses(data);
    if (user) {
      eductionScript();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      eductionScript();
    }
    // eslint-disable-next-line
  }, [courses]);

  console.log(displayCourses);

  function checkAttachments(data) {
    // Check attachments in the main object
    if (data.attachments && data.attachments.length > 0) {
      return data;
    }

    // Check attachments in modulesData
    if (data.modulesData) {
      for (const module of data.modulesData) {
        if (module.attachments && module.attachments.length > 0) {
          return module;
        }

        // Check attachments in units of each module (if units exist)
        if (module.units) {
          for (const unit of module.units) {
            if (unit.attachments && unit.attachments.length > 0) {
              return unit;
            }
          }
        }
      }
    }
  }

  return (
    <div
      id="education"
      className="flex-fill d-flex flex-row gut-100 section stretch"
    >
      <div className="col d-flex flex-column align-items-start justify-content-center content-box w-100">
        <h2 id="education-title" className="text-right">
          My <span className="font-weight-bold">Education</span>
        </h2>

        {isDisplayTitle && (
          <h3 className="font-weight-bold green">Current Qualifications</h3>
        )}

        {/* <div className="d-flex flex-column align-items-start justify-content-center w-100">
          <div
            id="education-tiles"
            className="row w-100 d-flex gap-50  stretch"
          >
            {user?.courseData?.map((cardData, i) => {
              return (
                <>
                  <Card cardData={cardData} index={i} />
                  {(i + 1) % 3 === 0 && (
                    <>
                      <div className="w-100"></div>
                      <ExpandInfo eductionData={user?.courseData} index={i} />
                    </>
                  )}
                </>
              );
            })}
          </div>
        </div> */}

        {courses?.map((data, ix) => {
          return (
            <div className="d-flex flex-column align-items-start justify-content-center w-100">
              <div
                id="education-tiles"
                className="row w-100 d-flex flex-nowrap gap-50  stretch"
              >
                {displayCourses?.slice(data, data + 3)?.map((cardData, i) => (
                  <Card
                    key={i}
                    cardData={cardData}
                    index={data + i}
                    setIsDisplayTitle={setIsDisplayTitle}
                  />
                ))}
              </div>
              <ExpandInfo
                key={ix}
                eductionData={user?.courseData?.slice(data, data + 3)}
                indexData={data}
              />
            </div>
          );
        })}

        {/* <div className="d-flex flex-column align-items-start justify-content-center w-100">
          <div
            id="education-tiles"
            className="row w-100 d-flex flex-nowrap gap-50  stretch"
          >
                {user?.courseData?.slice(data, data + 3)?.map((cardData, i) => (
                  <Card key={i} cardData={cardData} index={data + i} />
                ))}
              </div>
              <ExpandInfo
                eductionData={user?.courseData?.slice(data, data + 3)}
                indexData={data}
              />
            </div>
          );
        })}
*/}
        {/* <div className="d-flex flex-column align-items-start justify-content-center w-100">
          <div
            id="education-tiles"
            className="row w-100 d-flex flex-nowrap gap-50  stretch"
          >
            {user?.courseData?.slice(0, 3)?.map((cardData, i) => (
              <Card key={i} cardData={cardData} index={i} />
            ))}
          </div>
          <ExpandInfo eductionData={user?.courseData?.slice(0, 3)} />
        </div> */}

        {/* <div className="d-flex flex-column align-items-start justify-content-center w-100">
          <div
            id="education-tiles"
            className="row w-100 d-flex gap-50  stretch"
          >
            {user?.courseData?.slice(3, 6)?.map((cardData, i) => (
              <Card key={i} cardData={cardData} index={i + 3} />
            ))}
          </div>
          <ExpandInfo eductionData={user?.courseData?.slice(3, 6)} />
        </div> */}

        <ExistingQualifications />

        <MiscAttributes Category="education" />
      </div>
    </div>
  );
};

export default Education;
