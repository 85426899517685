import Icons from "assets/icons/Icons";
import address from "assets/images/address.svg";
import mail from "assets/images/mail.svg";
import phone from "assets/images/phone.svg";
import Image from "components/Image";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { popUp } from "components/Popup/Popup.Script";

const Popup = () => {
  let { user } = useSelector((state) => state.user);

  useEffect(() => {
    popUp();
  }, [user]);

  return (
    <div id="popup" className="position-fixed d-flex flex-column">
      <div id="popup-close" className="green position-absolute">
        <Icons name="popup" stroke={user?.brandTheme?.themeColors?.primary} />
      </div>
      <h5>
        {user?.name ? user?.name?.split(" ")[0] : ""}{" "}
        <span className="font-weight-bold">
          {user?.name ? user?.name?.split(" ")[1] : ""}
        </span>
      </h5>
      <div className="contact-details d-flex flex-column">
        <div
          id="phone-row"
          className="d-flex flex-row contact-row align-items-center"
        >
          {/* <Image className="contact-icon" src={phone} /> */}
          <Icons name="phone" fill={user?.brandTheme?.themeColors?.primary} />

          <a href={`tel:${user?.contact ? user?.contact : ""}`}>
            <p>{user?.contact ? user?.contact : "(+44) 7777 777 777"}</p>
          </a>
        </div>

        <div
          id="email-row"
          className="d-flex flex-row contact-row align-items-center"
        >
          {/* <Image className="contact-icon" src={mail} /> */}
          <Icons name="e-mail" fill={user?.brandTheme?.themeColors?.primary} />
          <a href={`mailto:${user?.email ? user?.email : ""}`}>
            <p>{user?.email ? user?.email : ""}</p>
          </a>
        </div>
        <div
          id="address-row"
          className="d-flex flex-row contact-row align-items-center"
        >
          {/* <Image className="contact-icon" src={address} /> */}
          <Icons name="address" fill={user?.brandTheme?.themeColors?.primary} />
          <p>
            {user?.address
              ? user?.address
              : "XX Lorem Street, Ipsumville, Dolor, XX00XX"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Popup;
