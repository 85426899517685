import React from "react";
import { useSelector } from "react-redux";
import SkillModel from "components/FourCornerModel/SkillModel/SkillModel";
import Card from "components/Interests/Card";
import MiscAttributes from "components/MiscAttributes";

const formatInterests = (physical_attributes) => {
  return (
    physical_attributes?.map((items) => {
      if (!items?.templateId) {
        return {
          title: items?.title,
          description: items?.description,
        };
      } else {
        const formattedValues = { templateId: items?.templateId };
        items?.value?.forEach((item) => {
          formattedValues[item?.title] = item?.value;
        });
        return formattedValues;
      }
    }) || []
  );
};

const FourCornerModel = () => {
  let { user } = useSelector((state) => state.user);

  const skills = [
    // {
    //   type: "Technical",
    //   categories: [
    //     {
    //       htmlId: "passing-header",
    //       category: "Passing",

    //       sub_skills: [
    //         { name: "One touch", level: 4 },
    //         { name: "Short", level: 2 },
    //         { name: "Long", level: 2 },
    //         { name: "Under Pressure", level: 2 },
    //         { name: "On the ground", level: 1 },
    //         { name: "In the air", level: 4 },
    //       ],
    //     },
    //     {
    //       htmlId: "turning-header",
    //       category: "Turning",
    //       sub_skills: [
    //         { name: "With the ball", level: 4 },
    //         { name: "Without the ball", level: 2 },
    //         { name: "Under Pressure", level: 2 },
    //         // { name: "", level: "" },
    //         // { name: "", level: "" },
    //         // { name: "", level: "" },
    //       ],
    //     },
    //   ],
    // },
    {
      type: "Physical",
      categories: [
        {
          htmlId: "speed-header",
          category: "Speed",
          sub_skills: user?.speed,
        },
        {
          htmlId: "agility-header",
          category: "Agility",
          sub_skills: user?.agility,
        },
      ],
    },
  ];

  const filteredSkills = skills
    .map((skill) => ({
      ...skill,
      categories: skill.categories.filter(
        (category) =>
          category.sub_skills &&
          Array.isArray(category.sub_skills) &&
          category.sub_skills.length > 0
      ),
    }))
    .filter((skill) => skill.categories.length > 0);

  if (filteredSkills.length === 0) {
    return null;
  }

  const formattedInterests = formatInterests(user?.physical_attributes);

  // Group interests by templateId
  const groupedInterests = formattedInterests.reduce((acc, interest) => {
    const { templateId } = interest;

    if (!templateId) {
      acc[1] = acc[1] || [];
      acc[1].push(interest);
      return acc;
    }
    acc[templateId] = acc[templateId] || [];
    acc[templateId].push(interest);
    return acc;
  }, {});

  // const template1 = (data) => (

  //   <div className="row w-100 d-flex gap-50 flex-nowrap interest-row">
  //     {data?.map((data) => (
  //       <div className="col-md-4 p-0 d-flex stretch flex-unset fourCornModel_card">
  //         <Card interestsCardData={[data]} />
  //       </div>
  //     ))}
  //   </div>
  // );

  const template1 = (data) => {
    const templateArray = [];
    for (var i = 0; i < data?.length; i += 3) {
      templateArray.push(
        <div className="row w-100 d-flex gap-50 flex-nowrap interest-row">
          {data?.slice(i, i + 3)?.map((data) => (
            <div className="col-md-4 p-0 d-flex stretch flex-unset">
              <Card interestsCardData={[data]} />
            </div>
          ))}
        </div>
      );
    }
    return templateArray;
  };

  const template2 = (data) => (
    <div className="template2-container w-100 d-flex flex-column gap-50">
      {data.map((interest, index) => (
        <div key={index} className="template2-item">
          <Card interestsCardData={[interest]} />
        </div>
      ))}
    </div>
  );

  const template3 = (data) => (
    <div className="template3-container w-100">
      <div className="template3-item">
        <div className="row d-flex flex-row align-items-center flex-nowrap gap-50 skills-row">
          {data?.map((interest, index) => (
            <Card interestsCardData={[interest]} />
          ))}
        </div>
      </div>
    </div>
  );

  const renderTemplates = () => {
    return Object.keys(groupedInterests).map((templateId) => {
      switch (templateId) {
        case "1":
          return template1(groupedInterests[templateId]);
        case "2":
          return template2(groupedInterests[templateId]);
        case "3":
          return template3(groupedInterests[templateId]);
        default:
          return null;
      }
    });
  };

  return (
    <div
      id="four-corner-model"
      className="flex-fill d-flex flex-row gut-100 section"
    >
      <div className="col d-flex flex-column align-items-start justify-content-center content-box">
        <h2 id="previous-clubs-title" className="text-right">
          My <span className="font-weight-bold">Physical Markers</span>
        </h2>
        <div id="technical" className="d-flex flex-column gap-50 w-100">
          {skills?.map((data) => (
            <>
              <h5 className="green font-weight-bold">{data?.type}</h5>
              {data?.categories?.map((categories) => (
                <SkillModel
                  skill_categories={categories}
                  skillType={data?.type}
                />
              ))}
            </>
          ))}
        </div>

        {renderTemplates()}

        {/* <div className="row w-100 d-flex gap-50 flex-wrap interest-row">
          {user?.physical_attributes?.map((data) => (
            <div className="col-md-4 p-0 d-flex stretch flex-unset fourCornModel_card">
              <Card interestsCardData={data} />
            </div>
          ))}
        </div> */}

        <MiscAttributes Category="physical markers" />
      </div>
    </div>
  );
};

export default FourCornerModel;
