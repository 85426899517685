import { generateChartOptions } from "components/TransferableSkills/TransferableSkills.Apexchart";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import MiscAttributes from "components/MiscAttributes";

const TransferableSkills = () => {
  let { user } = useSelector((state) => state.user);
  // const skillValue = user?.transferableSkills?.map((data) => {
  //   data.chartOptions = generateChartOptions(data?.point);
  //   return data;
  // });

  useEffect(() => {
    if (user) {
      // Create an array of chart instances using map
      const charts = user?.transferableSkills?.map((item, i) => {
        // eslint-disable-next-line
        return new ApexCharts(
          document.querySelector(
            `#${item?.name?.split(" ")?.join("")}-chart-${i}`
          ),
          generateChartOptions(item?.point)
        );
      });

      // Render each chart
      charts?.forEach((chart) => {
        chart.render();
      });
    }
  }, [user]);

  // const transferableValues = [
  //   {
  //     outerDivId: "adaptability",
  //     innerDivId: "adaptability-chart",
  //     title: "Adaptability",
  //     chartOptions: generateChartOptions(6),
  //   },
  //   {
  //     outerDivId: "assertiveness",
  //     innerDivId: "assertiveness-chart",
  //     title: "Assertiveness",
  //     chartOptions: generateChartOptions(8),
  //   },

  //   {
  //     outerDivId: "acceptance",
  //     innerDivId: "acceptance-chart",
  //     title: "Acceptance",
  //     chartOptions: generateChartOptions(10),
  //   },
  //   {
  //     outerDivId: "consideration",
  //     innerDivId: "consideration-chart",
  //     title: "Consideration",
  //     chartOptions: generateChartOptions(7),
  //   },
  // ];

  return (
    <div
      id="transferable-values"
      className="flex-fill d-flex flex-row gut-100 section"
    >
      <div className="col d-flex flex-column align-items-start justify-content-center content-box">
        <h2 id="transferable-values-title">
          My <span className="font-weight-bold">Transferable Skills</span>
        </h2>
        <div className="d-flex flex-column gap-50 w-100">
          <div
            className="d-flex flex-wrap flex-row gap-50 w-100 values-row"
            style={{ alignItems: "start" }}
          >
            {user?.transferableSkills?.map((data, i) => (
              <div
                // id="adaptability"
                id={data?.name?.split(" ")?.join("") + i}
                className="col-md-3 d-flex gap-25 flex-column justify-content-center align-items-center flex-unset p-0 values-item"
                // style={{ maxWidth: "22%" }}
              >
                <h5 className="font-weight-bold green chart-title">
                  {data?.name[0]?.toUpperCase() +
                    data?.name?.slice(1)?.toLowerCase()}
                </h5>

                <div
                  // id="adaptability-chart"
                  id={`${data?.name?.split(" ")?.join("")}-chart-${i}`}
                  className="apexchart"
                ></div>
                <p className="font-weight-bold chart-title text-center">
                  {data?.comment}
                </p>
              </div>
            ))}
          </div>
        </div>

        <MiscAttributes Category="transferable skills" />
      </div>
    </div>
  );
};

export default TransferableSkills;
