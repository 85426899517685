import React, { useEffect, useState } from "react";
import Card from "components/PreviousClubs/Card/Card";
import { useSelector } from "react-redux";
import MiscAttributes from "components/MiscAttributes";

const PreviousClubs = () => {
  let { user } = useSelector((state) => state.user);
  const [previousClubs, setPreviousClubs] = useState([]);

  useEffect(() => {
    let clubs =
      user?.previous_clubs?.length > 0
        ? user?.previous_clubs.sort((a, b) => {
            return Number(a.startDate) - Number(b.startDate);
          })
        : [];
    setPreviousClubs([...clubs]);
  }, [user]);

  return (
    <div
      id="previous-clubs"
      className="flex-fill d-flex flex-row gut-100 section"
    >
      <div className="col d-flex flex-column align-items-start justify-content-center content-box">
        <h2 id="previous-clubs-title" className="text-right">
          My <span className="font-weight-bold">Previous Clubs</span>
        </h2>
        <div className="row d-flex flex-row gap-50 w-100 flex-nowrap club-row">
          {/* {previousClubs?.length > 0 &&
            previousClubs
              ?.slice(previousClubs?.length - 3, previousClubs?.length)
              ?.reverse()
              ?.map((data) => <Card clubData={data} />)} */}
          {previousClubs?.length > 0 &&
            previousClubs
              ?.slice(-3)
              ?.reverse()
              ?.map((data) => <Card clubData={data} />)}
        </div>

        <MiscAttributes Category="previous clubs" />
      </div>
    </div>
  );
};

export default PreviousClubs;
