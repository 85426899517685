// import ApexCharts from "apexcharts";

/* transferable values */
export function generateChartOptions(value) {
  var minAngle = -135; // Start angle
  var maxAngle = 135; // End angle

  var adaptoptions = {
    series: [value * 10],
    // options: {
    chart: {
      // width: "100%",
      // height: "auto",
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    responsive: [
      {
        breakpoint: 425, // Mobile
        options: {
          chart: {
            width: "60%", // Full width for mobile
          },
          legend: {
            position: "bottom",
            horizontalAlign: "center",
          },
        },
      },
      {
        breakpoint: 768, // Mobile
        options: {
          chart: {
            width: "65%", // Full width for mobile
          },
        },
      },
      {
        breakpoint: 1024, // Mobile
        options: {
          chart: {
            width: "70%", // Full width for mobile
          },
        },
      },
    ],

    plotOptions: {
      radialBar: {
        startAngle: minAngle,

        endAngle: maxAngle,
        hollow: {
          margin: 10,
          size: "85%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: false,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "0%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: false,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },
        dataLabels: {
          show: true, // Hide the default series label
          value: {
            formatter: function (val) {
              return value; // Display the original value as the label
            },

            color: "#006b00",
            fontSize: "60px",
            fontWeight: "700",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0,
        gradientFromColors: ["#65a8d1"],
        gradientToColors: ["#006b00"],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    // },
  };

  return adaptoptions;
}

// Generate options for each chart
var adaptabilityvalue = 6;
var adaptabilityoptions = generateChartOptions(adaptabilityvalue);

var assertivenessvalue = 8;
var assertivenessoptions = generateChartOptions(assertivenessvalue);

var acceptancevalue = 10;
var acceptanceoptions = generateChartOptions(acceptancevalue);

var considerationvalue = 7;
var considerationoptions = generateChartOptions(considerationvalue);

// Create charts

// eslint-disable-next-line

// eslint-disable-next-line
// var adaptability1 = new ApexCharts(
//   document.querySelector("#adaptability-chart"),
//   adaptabilityoptions
// );
// adaptability1.render();

// // eslint-disable-next-line
// var assertiveness = new ApexCharts(
//   document.querySelector("#assertiveness-chart"),
//   assertivenessoptions
// );
// assertiveness.render();

// // eslint-disable-next-line
// var acceptance = new ApexCharts(
//   document.querySelector("#acceptance-chart"),
//   acceptanceoptions
// );
// acceptance.render();

// // eslint-disable-next-line
// var consideration = new ApexCharts(
//   document.querySelector("#consideration-chart"),
//   considerationoptions
// );
// consideration.render();
