import Image from "components/Image";
import address from "assets/images/address.svg";
import mail from "assets/images/mail.svg";
import phone from "assets/images/phone.svg";
import { useSelector } from "react-redux";
import Icons from "assets/icons/Icons";

const Sidebar = () => {
  let { user } = useSelector((state) => state.user);

  return (
    <div className="leftCol">
      <div>
        <Image
          className="profileImg"
          src={
            user?.profile_image
              ? user?.profile_image
              : "https://routeresourcegroup.blob.core.windows.net/image/user-avatar-placeholder.png"
          }
        />
        <h1 className="leftTitle marginBottom">
          {user?.name ? user?.name?.split(" ")[0] : ""}
          <br />
          <span className="bold">
            {user?.name ? user?.name?.split(" ")[1] : ""}
          </span>
        </h1>
        <div className="colGrid2 marginBottom">
          <p>{`${user?.age ? user?.age : ""} years old`}</p>
          <p>{user?.height ? `${user?.height}cm` : ""}</p>
          <p> {user?.nationality ? user?.nationality : ""}</p>
          <p>{user?.weight ? `${user?.weight}kg` : ""}</p>
        </div>
        <p>{user?.playing_position ? user?.playing_position : ""}</p>
        <div className="flexRow marginBottom">
          <p>{user?.club?.name ? user?.club?.name : ""}</p>
          <Image
            className="currentClubBadge"
            src={user?.club?.image ? user?.club?.image : ""}
          />
        </div>
        <div className="greenBreak marginBottom"></div>
        <h2 className="leftTitle marginBottom">
          About <span className="bold">Me</span>
        </h2>
        <p className="marginBottom">{user?.bio ? user?.bio : ""}</p>
        <div className="greenBreak marginBottom"></div>
        <h2 className="leftTitle marginBottom">
          Tutor <span className="bold">Reference</span>
        </h2>
        <h1 className="leftTitle">
          <span className="bold">
            {user?.cvOverview ? user?.cvOverview?.tutor?.name : ""}
          </span>
        </h1>
        <h2 className="smallLeftTitle mT-5">
          {user?.cvOverview ? user?.cvOverview?.tutor?.designation : ""}
        </h2>
        <h2 className="smallLeftTitle mT-5 marginBottom">
          {user?.cvOverview ? user?.cvOverview?.tutor?.club?.name : ""}
        </h2>
        <p className="marginBottom">{user?.cvOverview?.tutorComments}</p>
        <div className="greenBreak marginBottom"></div>
      </div>
      <div>
        <div className="flexRow marginBottom">
          {/* <Image className="contactIcon" src={phone} /> */}
          <Icons
            name="phone"
            className="contactIcon"
            fill={user?.brandTheme?.themeColors?.primary}
          />
          {/* <p>(+44) 7777 777 777</p> */}
          <p>{user?.contact ? user?.contact : "(+44) 7777 777 777"}</p>
        </div>
        <div className="flexRow marginBottom">
          {/* <Image className="contactIcon" src={mail} /> */}
          <Icons
            name="e-mail"
            className="contactIcon"
            fill={user?.brandTheme?.themeColors?.primary}
          />
          <p>{user?.email ? user?.email : ""}</p>
        </div>
        <div className="flexRow marginBottom alignTop">
          {/* <Image className="contactIcon" src={address} /> */}
          <Icons
            name="address"
            className="contactIcon"
            fill={user?.brandTheme?.themeColors?.primary}
          />
          <p>
            {user?.address
              ? user?.address
              : "XX Lorem Street, Ipsumville, Dolor, XX00XX"}
          </p>
        </div>
        <div className="greenBreak marginBottom"></div>
        <div className="flexRow marginBottom">
          <div className="colGrid2">
            <p className="green bold">
              Scan the QR code for an interactive version of this CV!
            </p>
            <a href={`${user?.qrUrl ? user?.qrUrl : ""}`}>
              <Image id="QR" src={user?.qrCode ? user?.qrCode : ""} />
            </a>
          </div>
        </div>
        <div className="greenBreak marginBottom"></div>
        <p className="generated">
          GENERATED{" "}
          <span className="bold">
            {new Date().getDate() +
              "." +
              (new Date().getMonth() + 1) +
              "." +
              new Date().getFullYear()}
          </span>
        </p>
      </div>
    </div>
  );
};

export default Sidebar;
