import Image from "components/Image";
import Modal from "components/Modal";
import React, { useState } from "react";
import "./card.css";

const Template1 = ({ title, description }) => (
  <div className="card">
    <div className="card-body d-flex flex-column p-0">
      <h5 className="card-title font-weight-bold green">
        {title ? title : ""}
      </h5>
      <p className="card-text">{description ? description : ""}</p>
    </div>
  </div>
);

const Template2 = ({
  key,
  title,
  description,
  startDate,
  endDate,
  media,
  // selectedMedia,
  // setSelectedMedia,
}) => {
  // console.log("selectedMedia:::", selectedMedia);

  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setSelectedMedia(media);
    setIsModalOpen(true); // Open modal when image is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close modal when clicking outside
  };

  // const handleImageClick = () => {
  //   setSelectedMedia(media);
  // };

  return (
    <div className="personal-development-card d-flex w-100">
      <div className="personal-development-card-info d-flex flex-column">
        <h5 className="green font-weight-bold">{title ? title : ""}</h5>
        <p>{startDate && endDate ? `${startDate} - ${endDate}` : ""}</p>
        <p>{description ? description : ""}</p>
      </div>
      <div className="personal-media">
        <div className="d-flex justify-content-end flex-wrap">
          <div className="mediaCard">
            <div className="image-media-div" onClick={handleImageClick}>
              <img
                src={media ? media : ""}
                className="image-media w-100"
                alt="thumbnail"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Modal */}
      {/* {isModalOpen && (
        <div className="modal  d-block" tabIndex="-1" role="dialog">
          <div
            className="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="close p-2"
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
                <Image
                  className="image-media w-100"
                  src={selectedMedia || ""}
                  alt="Expanded View"
                />
              </div>
            </div>
          </div>
          <div
            className="modal-backdrop fade show"
            onClick={handleCloseModal}
          ></div>
        </div>
      )} */}

      {isModalOpen && (
        <div className="modal-container">
          {/* Background overlay with opacity */}
          <div className="modal-overlay" onClick={handleCloseModal}></div>
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body p-0">
                <button
                  type="button"
                  className="close p-2"
                  onClick={handleCloseModal}
                >
                  &times;
                </button>
                {/* Ensure image remains fully visible */}
                <Image
                  className="image-media w-100"
                  src={selectedMedia || ""}
                  alt="Expanded View"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Template3 = ({ title, numeric, metric }) => (
  <div className="col-md-2 d-flex flex-column gap-25 justify-content-center corner-model-box p-0 flex-unset skills-box physical-box">
    <p>{title}</p>

    <>
      <p
        className={`fcm-number  ${
          numeric === 0
            ? "white"
            : numeric === 1
            ? "red"
            : numeric === 2
            ? "orange"
            : "green"
        } d-flex justify-content-center align-items-center`}
      >
        {/* {`${data.value}s`} */}
        {numeric ? `${numeric}${metric}` : ""}
      </p>
    </>
  </div>
);

const Case1Template = ({ title, description }) => (
  <div className="card">
    <div className="card-body d-flex flex-column p-0">
      <h5 className="card-title font-weight-bold green">
        {title ? title : ""}
      </h5>
      <p className="card-text">{description ? description : ""}</p>
    </div>
  </div>
);

const Card = ({ interestsCardData }) => {
  // const [selectedMedia, setSelectedMedia] = useState(null);
  return (
    <>
      {interestsCardData?.length > 0 &&
        interestsCardData?.map((data, index) => {
          const {
            templateId,
            Title,
            Description,
            StartDate,
            EndDate,
            Media,
            Numeric,
            Metric,
          } = data;

          switch (templateId) {
            case 1:
              return (
                <Template1
                  key={index}
                  title={Title}
                  description={Description}
                />
              );

            case 2:
              return (
                <Template2
                  key={index}
                  title={Title}
                  description={Description}
                  startDate={StartDate}
                  endDate={EndDate}
                  media={Media}
                  // selectedMedia={selectedMedia}
                  // setSelectedMedia={setSelectedMedia}
                />
              );

            case 3:
              return (
                <Template3
                  key={index}
                  title={Title}
                  numeric={Numeric}
                  metric={Metric}
                />
              );

            default:
              return (
                <Case1Template
                  key={index}
                  title={data.title}
                  description={data.description}
                />
              );
          }
        })}
    </>
  );
};

export default Card;
