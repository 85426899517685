// import React from "react";
// import { useSelector } from "react-redux";
// import Card from "components/Interests/Card/Card";

// const formatInterests = (interests) => {
//   const formattedOutput = [];

//   interests?.forEach((interest) => {
//     if (!interest?.templateId) {
//       formattedOutput?.push({
//         title: interest?.title,
//         description: interest?.description,
//       });
//     } else {
//       const formattedValues = {};
//       interest?.value?.forEach((item) => {
//         formattedValues[item?.title] = item?.value;
//         formattedValues.templateId = interest?.templateId;
//       });
//       formattedOutput?.push(formattedValues);
//     }
//   });

//   return formattedOutput;
// };

// const Interests = () => {
//   let { user } = useSelector((state) => state.user);

//   const temp = formatInterests(user?.interests);

//   const getInterests = () => {
//     const interestsArray = [];
//     for (var i = 0; i < user?.interests?.length; i += 3) {
//       interestsArray.push(
//         <div className="row w-100 d-flex gap-50 flex-nowrap interest-row">
//           {user?.interests?.slice(i, i + 3)?.map((data) => (
//             <div className="col-md-4 p-0 d-flex stretch flex-unset">
//               <Card interestsCardData={temp} />
//             </div>
//           ))}
//         </div>
//       );
//     }
//     return interestsArray;
//   };

//   return (
//     <div id="interests" className="flex-fill d-flex flex-row gut-100 section">
//       <div className="col d-flex flex-column align-items-start justify-content-center content-box">
//         <h2 id="interests-title" className="text-right">
//           My <span className="font-weight-bold">Interests</span>
//         </h2>
//         {getInterests()}
//       </div>
//     </div>
//   );
// };

// export default Interests;

import React from "react";
import { useSelector } from "react-redux";
import Card from "components/Interests/Card/Card";

const formatInterests = (interests) => {
  return (
    interests?.map((interest) => {
      if (!interest?.templateId) {
        return {
          title: interest?.title,
          description: interest?.description,
        };
      } else {
        const formattedValues = { templateId: interest?.templateId };
        interest?.value?.forEach((item) => {
          formattedValues[item?.title] = item?.value;
        });
        return formattedValues;
      }
    }) || []
  );
};

const Interests = () => {
  const { user } = useSelector((state) => state.user);
  const formattedInterests = formatInterests(user?.interests);

  // Group interests by templateId
  const groupedInterests = formattedInterests?.reduce((acc, interest) => {
    const { templateId } = interest;

    if (!templateId) {
      acc[1] = acc[1] || [];
      acc[1].push(interest);
      return acc;
    }
    acc[templateId] = acc[templateId] || [];
    acc[templateId]?.push(interest);
    return acc;
  }, {});

  const getInterests = (data) => {
    const interestsArray = [];
    for (var i = 0; i < data?.length; i += 3) {
      interestsArray?.push(
        <div className="row w-100 d-flex gap-50 flex-nowrap interest-row">
          {data?.slice(i, i + 3)?.map((interest) => (
            <div className="col-md-4 p-0 d-flex stretch flex-unset">
              <Card interestsCardData={[interest]} />
            </div>
          ))}
        </div>
      );
    }
    return interestsArray;
  };

  const template2 = (data) => (
    <div className="template2-container w-100 d-flex flex-column gap-50">
      {data.map((interest, index) => (
        <div key={index} className="template2-item">
          <Card interestsCardData={[interest]} />
        </div>
      ))}
    </div>
  );

  const template3 = (data) => (
    <div className="template3-container w-100">
      <div className="template3-item">
        <div className="row d-flex flex-row align-items-center flex-nowrap gap-50 skills-row">
          {data.map((interest, index) => (
            <Card interestsCardData={[interest]} />
          ))}
        </div>
      </div>
    </div>
  );

  // Function to render the appropriate template
  const renderTemplates = () => {
    return Object?.keys(groupedInterests)?.map((templateId) => {
      switch (templateId) {
        case "1":
          return getInterests(groupedInterests[templateId]);
        case "2":
          return template2(groupedInterests[templateId]);
        case "3":
          return template3(groupedInterests[templateId]);
        default:
          return null;
      }
    });
  };

  return (
    <div id="interests" className="d-flex flex-row gut-100 section">
      <div className="col d-flex flex-column align-items-start content-box">
        <h2 id="interests-title" className="text-right">
          My <span className="font-weight-bold">Interests</span>
        </h2>
        {renderTemplates()}
      </div>
    </div>
  );
};

export default Interests;
